import langsaLogo from "../assets/images/logo_langsa.svg";
import kmsLogo from "../assets/images/logo_kms.svg";
import RightArrowGray from "../assets/images/right_arrow_gray.svg";
import RightArrowWhite from "../assets/images/right_arrow_white.svg";

export default function AppSelection() {
  return (
    <div className="flex h-screen">
      <div className="flex-1 flex justify-center items-center bg-gradient-to-b from-[#472AFE] to-[#396CFE]">
        <div className="text-center text-white">
          <div className="mb-4 h-12 flex items-center justify-center">
            <img src={langsaLogo} alt="kms logo" className="h-full mb-3" />
          </div>
          <p className="text-xs text-gray-200 mb-2">
            랭사 AI와 대화를 시작해보세요
          </p>
          <h2 className="text-4xl font-bold mb-9">Playground</h2>
          <button
            className="mt-4 bg-transparent px-12 py-2.5 text-sm font-semibold text-white ring-1 ring-inset ring-white hover:bg-[#1E90FF] flex items-center mx-auto"
            onClick={() => {
              window.location.assign(process.env.REACT_APP_PLAYGROUND_ENDPOINT);
            }}
          >
            시작하기
            <img
              src={RightArrowWhite}
              alt="icon"
              className="h-[13px] w-[13px] ml-2"
            />
          </button>
        </div>
      </div>

      <div className="flex-1 flex justify-center items-center bg-[#FFFFFF]">
        <div className="text-center text-[#292929]">
          <div className="mb-4 h-12 flex items-center justify-center">
            <img src={kmsLogo} alt="kms logo" className="h-full mb-3" />
          </div>
          <p className="text-xs text-gray-600 mb-2">
            랭사 모델을 학습시켜 보세요
          </p>
          <h2 className="text-4xl font-bold mb-9">AI KMS</h2>
          <button
            className="mt-4 bg-transparent px-12 py-2.5 text-sm font-semibold text-[#292929] ring-1 ring-inset ring-[#292929] hover:bg-[#E5E7EB] flex items-center mx-auto"
            onClick={() => {
              window.location.assign(process.env.REACT_APP_AI_KMS_ENDPOINT);
              // window.location.replace(process.env.REACT_APP_AI_KMS_ENDPOINT); // history에 남지 않음
            }}
          >
            시작하기
            <img
              src={RightArrowGray}
              alt="icon"
              className="h-[13px] w-[13px] ml-2"
            />
          </button>
        </div>
      </div>
    </div>
  );
}
