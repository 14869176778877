import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import LangsaLogo from "../assets/images/langsa_logo_with_text.svg";
import PasswordImg from "../assets/images/password_input.svg";
import log from "../utils/logger";
import usersService from "../services/users";
import useSession from "../services/useSession";
import useStorage from "../hooks/useStorage";
import { delay } from "../utils/utils";
import defineConfig from "../config/defineConfig";

export default function Login() {
  const navigate = useNavigate();
  const emailIdRef = useRef();
  const { removeStorageItem } = useStorage();
  const { setSessionSetting } = useSession();

  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [accessDenied, setAccessDenied] = useState(false);

  useEffect(() => {
    removeStorageItem("clear()");
    delay(() => emailIdRef.current.focus());
  }, []);

  const handler = {
    emailId: (e) => {
      e.preventDefault();
      setEmailId(e.target.value);
    },
    password: (e) => {
      e.preventDefault();
      setPassword(e.target.value);
    },
    submit: (e) => {
      e.preventDefault();

      log.debug(`[ login ] => 입력 데이터 확인`, {
        emailId: emailId,
        password: password,
      });

      setAccessDenied(false);

      usersService
        .login({ emailId: emailId, password: password })
        .then((data) => {
          const { code, result } = data;

          switch (code) {
            case 200:
              setSessionSetting({
                name: result.name,
                email: result.id,
                workspace: result.personalWorkspaceId,
                knowledge: result.knowledgeId,
              }).then((response) => {
                if (response.code === 200) {
                  navigate("/");
                  // navigate("/app/selection");
                } else {
                  navigate(defineConfig.Route.LOGIN);
                }
              });
              break;
            case 600:
              log.debug("미승인 아이디");
              setAccessDenied(true);
              emailIdRef.current.focus();
              break;
            case 2200:
              log.debug("아이디 없음");
              setAccessDenied(true);
              emailIdRef.current.focus();
              break;
            case 2300:
              log.debug("비밀번호 불일치");
              setAccessDenied(true);
              emailIdRef.current.focus();
              break;
            default:
              setAccessDenied(true);
              emailIdRef.current.focus();
          }
        });
    },
  };

  return (
    <>
      <HelmetProvider>
        <Helmet
          htmlAttributes={{
            class: "h-full bg-[#F2F7FF] langsa-scrollType-1",
          }}
          bodyAttributes={{
            class: "h-full ntube-scrollType-1",
          }}
        />
      </HelmetProvider>

      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-[17vh] lg:px-8">
        <div className="h-12 flex-shrink-0" />
        <div className="sm:mx-auto sm:w-full sm:max-w-sm flex justify-center items-center">
          <img src={LangsaLogo} alt="logo" className="w-1/2" />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={handler.submit}>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="off" // or email
                placeholder="이메일"
                required
                className="block w-full rounded-full border-0 bg-[#FFFFFF] pl-6 py-3 text-black ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-[#396CFE] sm:text-sm sm:leading-6"
                value={emailId}
                ref={emailIdRef}
                onChange={handler.emailId}
                onKeyUp={(event) => {
                  if (event.key === "Escape") setEmailId("");
                }}
              />
            </div>
            {accessDenied && (
              <div className="mt-1 mr-2 mb-[-5px] font-semibold text-xs leading-5 flex items-center justify-end text-red-600">
                아이디 또는 비밀번호를 잘못 입력했습니다.
              </div>
            )}

            <div className="mt-4 relative flex items-center">
              <input
                id="password"
                name="password"
                type="password"
                placeholder="비밀번호"
                autoComplete="current-password"
                required
                className="block w-full rounded-full border-0 bg-[#FFFFFF] pl-6 py-3 text-black ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-[#396CFE] sm:text-sm sm:leading-6"
                value={password}
                onChange={handler.password}
                onKeyUp={(event) => {
                  if (event.key === "Escape") setPassword("");
                }}
              />
              <img
                src={PasswordImg}
                alt="icon"
                className="absolute right-5 h-4 w-4"
              />
            </div>

            <div className="mt-4">
              <button
                type="submit"
                className="flex w-full justify-center rounded-full bg-[#396CFE] mb-5 px-3.5 py-3 text-base font-medium leading-6 text-white shadow-sm hover:bg-[#4A7BFF] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                로그인
              </button>
            </div>
          </form>

          <div className="flex items-center justify-center">
            <button
              type="button"
              className="mb-5 text-sm font-semibold text-black hover:text-[#4F4F4F]"
              onClick={() => navigate("/update-password")}
            >
              비밀번호 찾기
            </button>
          </div>

          <button
            type="button"
            className="flex w-full justify-center rounded-md bg-[#F2F7FF] px-3.5 py-3 text-sm font-semibold text-[#396CFE] ring-1 ring-inset ring-[#0057FF45] hover:bg-[#D6E2FF]"
            onClick={() => alert("작업중")}
          >
            회원가입
          </button>
        </div>
      </div>
    </>
  );
}
