import { useContext, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContentContext } from "../../pages/context";
import PopupMenu from "./PopupMenu";
import Profile from "./Profile";
import useSession from "../../services/useSession";
import defineConfig from "../../config/defineConfig";
import useStorage from "../../hooks/useStorage";

function Header({ headerIsView }) {
  const navigate = useNavigate();
  const { removeStorageItem } = useStorage();
  const { getSession } = useSession();

  const {
    state: { contentType, userInfo },
    handleUserInfo,
    headerTextColor,
  } = useContext(ContentContext);

  const [isShowMenu, setIsShowMenu] = useState(false);
  const [isView, setIsView] = useState(false);

  const handleMenu = () => {
    setIsShowMenu((prev) => !prev);
  };

  const closeMenu = () => {
    setIsShowMenu(false);
  };

  const onClickLogin = () => {
    if (defineConfig.isServer) {
      removeStorageItem("path");
      navigate(defineConfig.Route.LOGIN);
    } else {
      handleUserInfo({
        isLogin: true,
        name: defineConfig.user.test.name,
        email: defineConfig.user.test.email,
        workspaceId: "1234",
        imgSrc: "",
      });
      setIsView(true);
    }
  };

  useLayoutEffect(() => {
    if (userInfo.name === "") {
      getSession()
        .then((response) => {
          console.log("getSession response >>>> ", response);
          if (response.code === 200) {
            handleUserInfo({
              isLogin: true,
              name: response.result.currentData.setting.name,
              email: response.result.currentData.setting.email,
              workspaceId: response.result.currentData.setting.workspace,
            });
            setIsView(true);
          } else {
            setIsView(true);
          }
        })
        .catch((error) => {
          console.error("getSession error >>>> ", error);
          // navigate("/", { replace: true });
          setIsView(true);
        });
    }
  }, [userInfo]);

  return (
    isView && (
      <div className="flex w-full h-[40px] items-center justify-between">
        {headerIsView && (
          <>
            <div className="flex text-[18px] leading-[20px] font-[600]">
              {contentType === "kms" && defineConfig.ContentList.kms.title}
            </div>
            <div className="flex items-center justify-end space-x-[20px]">
              <div>
                <button
                  type="button"
                  className={`text-[18px] leading-[24px] font-[500] ${headerTextColor}`}
                  onClick={handleMenu}
                >
                  Menu
                </button>
                {isShowMenu && <PopupMenu closeMenu={closeMenu} />}
              </div>
              <div className={`${headerTextColor}`}>|</div>
              {userInfo.isLogin ? (
                <Profile userInfo={userInfo} textColor={headerTextColor} />
              ) : (
                <button
                  type="button"
                  onClick={onClickLogin}
                  className={`w-[130px] h-[40px] rounded-[32px] bg-[#0085FF] text-[18px] leading-[21px] font-[600] ${headerTextColor}`}
                >
                  LOGIN
                </button>
              )}
            </div>
          </>
        )}
      </div>
    )
  );
}

export default Header;
