import { ReactComponent as IconMenuPlayground } from "../../assets/images/icons/icon-menu-playground.svg";
import { ReactComponent as IconMenuAIKMS } from "../../assets/images/icons/icon-menu-ai_kms.svg";
import { ReactComponent as IconMenuAIBuilder } from "../../assets/images/icons/icon-menu-ai_builder.svg";
import { ReactComponent as IconMenuHome } from "../../assets/images/icons/icon-menu-home.svg";
import { ReactComponent as IconLogout } from "../../assets/images/icons/icon-menu-home.svg";
import defineConfig from "../../config/defineConfig";

export const MenuList = [
  {
    icon: <IconMenuHome />,
    title: defineConfig.ContentList.search.title,
    url: defineConfig.ContentRoute.search,
    micro: false,
    // url: "/app/selection",
  },
  {
    icon: <IconMenuPlayground />,
    title: defineConfig.ContentList.playground.title,
    url: defineConfig.ContentRoute.playground,
    micro: true,
  },
  {
    icon: <IconMenuAIKMS />,
    title: defineConfig.ContentList.kms.title,
    url: defineConfig.ContentRoute.kms,
    micro: true,
  },
  {
    icon: <IconMenuAIBuilder />,
    title: defineConfig.ContentList.scenario_builder.title,
    url: defineConfig.ContentRoute.builder,
    micro: true,
  },
  {
    icon: <IconLogout />,
    title: "Logout",
    url: "/logout",
    micro: false,
  },
];
