import { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import usersService from "./services/users";
import LoadingSpinner from "./components/LoadingSpinner";
import defineConfig from "./config/defineConfig";
import { delay } from "./utils/utils";

function Root() {
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const shouldCheckLoginStatus = () =>
      !["/", "/login"].includes(location.pathname);

    if (!shouldCheckLoginStatus()) return setIsLoading(false);

    if (defineConfig.isServer) {
      usersService.isLoggedIn().then((res) => {
        if (res.code !== 200 || !res.result?.loginFlag) {
          alert("세션이 만료되었습니다. 로그인 페이지로 이동합니다.");
          navigate("/", { replace: true });
        } else {
          delay(() => setIsLoading(false));
        }
      });
    } else {
      delay(() => setIsLoading(false));
    }
  }, [location.pathname]);

  if (isLoading) return <LoadingSpinner />;

  return (
    <div className="font-roboto">
      <Outlet />
    </div>
  );
}

export default Root;
