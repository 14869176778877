import { createBrowserRouter } from "react-router-dom";

import Root from "./Root";
import Main from "./pages/Main";
import Login from "./pages/Login";
import AppSelection from "./pages/AppSelection";
import NotFound from "./pages/error/NotFound";
import ErrorComponent from "./pages/error/ErrorComponent";

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Root />,
      children: [
        {
          path: "",
          element: <Main />,
          errorElement: <ErrorComponent />,
        },
        {
          path: "login",
          element: <Login />,
          errorElement: <ErrorComponent />,
        },
        {
          path: "app/selection",
          element: <AppSelection />,
          errorElement: <ErrorComponent />,
        },
      ],
      errorElement: <NotFound />,
    },
  ],
  {
    basename: "/",
  },
);

export default router;
